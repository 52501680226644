import { h } from "preact";
import { useState } from "preact/hooks";

const TestDiscardDialog = () => {
  const [applicationName, setApplicationName] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  return (
    <div className="tui-test-area">
      <div className="tui-notification-input">
        <input
          data-testid="tui-dialog-application-name-input"
          className="tui-test-application-name"
          aria-label="Discard Application Name"
          placeholder="Discard Application Name"
          type="text"
          value={applicationName}
          onInput={(evt) => setApplicationName(evt.target.value)}
        />
        <input
          data-testid="tui-dialog-message-input"
          className="tui-test-message"
          aria-label="Discard Message"
          placeholder="Discard Message"
          type="text"
          value={message}
          onInput={(evt) => setMessage(evt.target.value)}
        />
      </div>
      <button
        className="tui-test-button"
        data-testid="tui-discard-intercept-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              applicationName,
              message,
              intercept: true
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Intercept
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-discard-clear-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              intercept: false
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Clear
      </button>
      <button
        className="tui-test-button"
        data-testid="tui-discard-immediate-button"
        onClick={() => {
          const event = new CustomEvent("test-discards", {
            detail: {
              applicationName,
              message,
              intercept: true,
              immediate: true
            }
          });
          document.dispatchEvent(event);
        }}
      >
        Immediate Intercept
      </button>
    </div>
  );
};

export default TestDiscardDialog;
