import { h } from "preact";
import { useState } from "preact/hooks";
import TestCountEvent from "./test-count-event";
import TestNofications from "./test-notifications";
import TestPatientContext from "./test-patient-context";
import TestMultipleEmisWebInstances from "./test-emis-web-multiple-instances";
import TestTimeout from "./test-emis-web-timeout";

const TestType = {
  patientContext: {
    name: "Patient context",
    component: <TestPatientContext />
  },
  countEvent: {
    name: "Count event",
    component: <TestCountEvent />
  },
  notifications: {
    name: "Notifications",
    component: <TestNofications />
  },
  emisWebMultipleInstances: {
    name: "Multiple EMIS Web",
    component: <TestMultipleEmisWebInstances />
  },
  emisWebTimeout: {
    name: "Timeout",
    component: <TestTimeout />
  }
};

const Test = () => {
  const [activeTest, setActiveTest] = useState(TestType.patientContext.name);

  const getTabClassName = (testType) => `tui-tablinks${activeTest === testType ? " selected" : ""}`;

  const getDataTestId = (testType) => `tui-test-${testType.toLowerCase().replace(" ", "-")}-tab`;

  return (
    <div>
      <div className="tui-tab">
        {" "}
        {Object.values(TestType).map(({ name }) => (
          <button
            key={name}
            className={getTabClassName(name)}
            data-testid={getDataTestId(name)}
            onClick={() => setActiveTest(name)}
          >
            {name}
          </button>
        ))}
      </div>
      {Object.values(TestType)
        .filter(({ name }) => activeTest === name)
        .map(({ component }) => component)}
    </div>
  );
};

export default Test;
